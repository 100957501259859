<script>
import NavBar from '@components/nav-bar'
import SideBar from '@components/side-bar'
export default {
  components: { NavBar, SideBar },
  data() {
    return {
      isMenuCondensed: false,
    }
  },
  created: () => {
    document.body.classList.remove('authentication-bg')
    document.body.classList.remove('authentication-bg-pattern')
  },
  mounted: () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        navigator.userAgent
      )
    ) {
      document.body.classList.add('enlarged')
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuCondensed = !this.isMenuCondensed
      document.body.classList.toggle('sidebar-enable')
      document.body.classList.toggle('enlarged')
    },
    toggleRightSidebar() {
      document.body.classList.toggle('right-bar-enabled')
    },
    hideRightSidebar() {
      document.body.classList.remove('right-bar-enabled')
    },
  },
}
</script>

<template>
  <div v-if="$store.state.clientMobile" id="wrapper">
      <div class="content">
        <div class="container-fluid">
          <slot />
        </div>
      </div>
  </div>


  <div v-else id="wrapper">
    <NavBar />
    <SideBar :is-condensed="isMenuCondensed" />

    <div class="content-page">
      <div class="content">
        <div class="container-fluid">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
