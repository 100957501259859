<script>
export default {
  created: () => {
    document.body.classList.add('authentication-bg')
    document.body.classList.add('authentication-bg-pattern')
  }
}
</script>

<template>
  <div>
    <div class="account-pages mt-5 mb-2">
      <div class="container">
        <slot />
      </div>
    </div>
  </div>
</template>
