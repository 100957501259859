<script>
import { authComputed } from '@state/helpers'

import MetisMenu from 'metismenujs/dist/metismenujs'
import { listOrganization }  from '@src/api/organizations'
import { branchesByOrganization} from '@src/api/partner-branch'

export default {
  components: {},
  data() {
    return {
      current: localStorage.getItem('currentBranch') ? JSON.parse(localStorage.getItem('currentBranch')).value : '',
      userAccess: JSON.parse(localStorage.getItem('currentUser')).roles,
      onlyOneOrganization: !!localStorage.getItem('one_organization'),
      username: JSON.parse(localStorage.getItem('currentUser')).user.name,
    }
  },
  computed: {
    ...authComputed,
  },
  mounted: function() {
    var menuRef = new MetisMenu('#side-menu')
    var links = document.getElementsByClassName('side-nav-link-ref')
    var matchingMenuItem = null
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i]
        break
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add('active')
      var parent = matchingMenuItem.parentElement

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active')
        const parent2 = parent.parentElement
        if (parent2) {
          parent2.classList.add('in')
        }
        const parent3 = parent2.parentElement
        if (parent3) {
          parent3.classList.add('active')
          var childAnchor = parent3.querySelector('.has-dropdown')
          if (childAnchor) childAnchor.classList.add('active')
        }

        const parent4 = parent3.parentElement
        if (parent4) parent4.classList.add('in')
        const parent5 = parent4.parentElement
        if (parent5) parent5.classList.add('active')
      }
    }
  },
  methods: {
    /**
     * small sidebar
     */
    smallSidebar() {
      document.body.classList.add('left-side-menu-sm')
      document.body.classList.remove('left-side-menu-dark')
      document.body.classList.remove('topbar-light')
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('enlarged')
    },

    /**
     * Dark sidebar
     */
    darkSidebar() {
      document.body.classList.remove('left-side-menu-sm')
      document.body.classList.add('left-side-menu-dark')
      document.body.classList.remove('topbar-light')
      document.body.classList.remove('boxed-layout')
    },

    /**
     * Light Topbar
     */
    lightTopbar() {
      document.body.classList.add('topbar-light')
      document.body.classList.remove('left-side-menu-dark')
      document.body.classList.remove('left-side-menu-sm')
      document.body.classList.remove('boxed-layout')
    },

    /**
     * Sidebar collapsed
     */
    sidebarCollapsed() {
      document.body.classList.remove('left-side-menu-dark')
      document.body.classList.remove('left-side-menu-sm')
      document.body.classList.toggle('enlarged')
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('topbar-light')
    },

    /**
     * Boxed Layout
     */
    boxedLayout() {
      document.body.classList.add('boxed-layout')
      document.body.classList.remove('left-side-menu-dark')
      document.body.classList.add('enlarged')
      document.body.classList.remove('left-side-menu-sm')
    },
    async checkStartRoute(){
      if(['admin_lecupon'].includes(this.userAccess)){
        this.goToOrganization()
      }
      else{
        if(this.onlyOneOrganization){
          const respOrg = await listOrganization({ page: 1 })
          const resp = await branchesByOrganization({
            orgId: respOrg.data[0].id,
            active: true,
            page: 1
          })
          if(resp.data.length == 1){
            this.$router.push({ path: `/filial/${resp.data[0].id}`})
          }
          else{
            this.$router.push({ 
              name: 'organizacao',
              params: { id: respOrg.data[0].id },        
            })
          }
        }
        else this.goToOrganization()
      }
    },
    goToOrganization () {
      if (this.$route.path !== '/organizacao') {
        this.$router.push({ path: '/organizacao' })
      }
    }
  },
}
</script>

<template>
  <div id="sidebar-menu">
    <ul id="side-menu" class="metismenu">
      <li class="menu-title">Bem Vindo {{username}}!</li>

      <li class="cursor-pointer">
        <a aria-expanded="false" class="has-dropdown" @click="checkStartRoute">
          <i class="fas fa-home"></i>
          <span>Início</span>
        </a>
      </li>

      <li v-if="!onlyOneOrganization">
        <a href="/organizacao"  aria-expanded="false" class="has-dropdown">
          <i class="fas fa-qrcode"></i>
          <span>Organizações</span>
        </a>
      </li>


      <li>
        <a href="javascript: void(0);" aria-expanded="false" class="has-dropdown">
          <i class="fas fa-ticket-alt"></i>
          <span>Promoções</span>
        </a>
        <ul class="nav-second-level" aria-expanded="false">
          <li v-if="['admin_lecupon','admin_shopkeeper'].includes(userAccess)">
            <a href="/nova-promocao" class="side-nav-link-ref">
              <span>Cadastrar Promoção</span>
            </a>
          </li>
          <li>
            <a href="/lista-promocao" class="side-nav-link-ref">
              <span>Gerenciar Promoções</span>
            </a>
          </li>
        </ul>
      </li>

      <li v-if="['admin_lecupon'].includes(userAccess)">
        <a href="/lista-categorias" aria-expanded="false" class="has-dropdown">
          <i class="fas fa-list"></i>
          <span>Categorias</span>
        </a>
      </li>

      <li v-if="['admin_lecupon'].includes(userAccess)">
        <a href="/resgates" aria-expanded="false" class="has-dropdown">
          <i class="fas fa-receipt"></i>
          <span>Resgates</span>
        </a>
      </li>

      <li v-if="['admin_lecupon','admin_shopkeeper'].includes(userAccess)">
        <a href="/usuarios" aria-expanded="false" class="has-dropdown">
          <i class="fas fa-users"></i>
          <span>Usuários</span>
        </a>
      </li>

      <li v-if="['admin_lecupon'].includes(userAccess)">
        <a href="/cashback" aria-expanded="false" class="has-dropdown">
          <i class="fas fa-cash-register"></i>
          <span>Cashback</span>
        </a>
      </li>

      <li v-if="['admin_lecupon'].includes(userAccess)">
        <a href="/ferramentas" aria-expanded="false" class="has-dropdown">
          <i class="fas fa-cogs"></i>
          <span>Ferramentas</span>
        </a>
      </li>
      
      <li>
        <a href="/logout"  aria-expanded="false" class="has-dropdown">
          <i class="fe-log-out mr-1"></i>
          <span>Sair</span>
        </a>
      </li>

    </ul>
    <div class="clearfix"></div>
  </div>
</template>

<style lang="scss">
@import '~metismenujs/scss/metismenujs';

.cursor-pointer{
  cursor: pointer
}
</style>
