<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import { listOrganization } from '@src/api/organizations';
import { branchByTerm, branchesByOrganization, listBranches } from '@src/api/partner-branch';

export default {
  components: { 
    vSelect
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  data() {
    return {
      user: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).user : '',
      userAccess: localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).roles : '',
      branches: [],
      selectedBranch: JSON.parse(localStorage.getItem('currentBranch')),
      observer: null,
      limit: 15,
      search: '',
      show: true,
      currentPage: 1,
      loading: true,
      onlyOneOrganization: !!localStorage.getItem('one_organization'),
      username: JSON.parse(localStorage.getItem('currentUser')).user.name,
    }
  },
  computed: {
    filtered () {
      return this.branches.filter(b => b.text.includes(this.search))
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    async hasNextPage () {
      return this.paginated.length < this.filtered.length
    },
  },
  watch:{
    selectedBranch: {
      async handler(data) {
        localStorage.setItem('currentBranch', JSON.stringify({
          text: data.text,
          value: data.value
        }))

        this.$router.go(this.$router.currentRoute)
      },
      deep: true,
    },
    search: {
      async handler(data) {
        if(data.length === 0) this.show = true
      },
      deep: true
    }
  },
  async beforeMount() {
    try {
      if(this.user && this.user.role !== 'admin_lecupon'){
        const responseBranches = await listBranches('', this.currentPage)
        this.setBranches(responseBranches.data)
        this.loading = false
      }
    } catch (err) {
      console.error(err.message)
    }
  },
  mounted () {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    async setBranches(item){
      try {
        for (const i of item) {
          this.branches.push({
            value: i.id,
            text: i.name
          })
        }
      } catch (err){
        console.error(err)
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu()
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar()
    },
    async logout(){
      try {
        localStorage.clear()
        this.$store.commit("setCurrentUserOff")
        this.$router.replace({ path: '/login' })        
      } catch (err) {
        console.error(err.stack)
      }
    },
    myUser(){
      try {        
        this.$router.push({ 
          name: 'usuario', 
          params: { id: this.user.id },  
          query: { edit: false }
        })
      } catch (err){
        console.error(err)
      }      
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 15
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    async searchTerm (){
      const response = await branchByTerm({ name: this.search })
      
      this.branches = []
           
      for (const i of response.data) {
        this.branches.push({
          value: i.id,
          text: i.name,
        })
      }
      this.search = ''
      this.show = true

      await this.$nextTick()
      this.$refs.vselect.open = true
    },
    async checkStartRoute(){
      if(['admin_lecupon'].includes(this.userAccess)){
        this.$router.push({ path: '/organizacao' })
      }
      else{
        if(this.onlyOneOrganization){
          const respOrg = await listOrganization({ page: 1 })
          const resp = await branchesByOrganization({
            orgId: respOrg.data[0].id,
            active: true,
            page: 1
          })
          if(resp.data.length == 1){
            this.$router.push({ path: `/filial/${resp.data[0].id}`})
          }
          else{
            this.$router.push({ 
              name: 'organizacao',
              params: { id: respOrg.data[0].id },        
            })
          }
        }
        else this.$router.push({ path: '/organizacao' })
      }
    }
  }
}
</script>

<template>
  <!-- Topbar Start -->
  <div class="navbar-custom">
    <ul class="list-unstyled topnav-menu float-right mb-0">

      <b-nav-item-dropdown right class="notification-list" menu-class="profile-dropdown">
        <template slot="button-content">
          <div class="nav-user mr-0">
            
            <span class="pro-user-name ml-1 text-white">              
              Opções
              <i class="mdi mdi-chevron-down"></i>
            </span>
          </div>
        </template>

        <b-dropdown-item @click="myUser">
          <i class="fas fa-user-alt"></i>
          <span>Perfil</span>
        </b-dropdown-item>

        <b-dropdown-item @click="logout">
          <i class="fe-log-out mr-1"></i>
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

    </ul>

    <!-- LOGO -->
    <div class="logo-box cursor-pointer" @click="checkStartRoute">
      <a  class="logo text-center">
        <span class="logo-lg">
          <img src="@assets/images/lecupon/alloyal_logo_white.png" alt=""/>
        </span>
        <span class="logo-sm">
          <span class="logo-sm text-light">a</span>
        </span>
      </a>
    </div>

    <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
      <li>
        <button class="button-menu-mobile" @click="toggleMenu">
          <i class="fe-menu"></i>
        </button>
      </li>
    </ul>
  </div>
  <!-- end Topbar -->
</template>

<style lang="scss">
.noti-scroll {
  height: 220px;
}
.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}
.button-menu-mobile {
  outline: none !important;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: rgba(255,255,255,0.9);
  border: none;
}


</style>
