import store from '@state/store'
import { listOrganization } from '@src/api/organizations'
import { branchesByOrganization } from '@src/api/partner-branch'

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        next({ name: 'login' })
      },
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('@views/login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (JSON.parse(localStorage.getItem('currentUser'))) {
          next({ name: 'organizacao' })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/forget_password',
    name: 'forget_password',
    component: () =>
      lazyLoadView(import('@/src/router/views/forgetPassword.vue')),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear()
        store.dispatch('setCurrentUserOff')
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404').default,
    props: true,
  },
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/organizacao',
    name: 'organizacoes',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/organization/ListOrganizations.vue')
      ),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        if (!localStorage.getItem('currentUser')) {
          next({ name: 'login' })
        } else if (
          ['admin_lecupon'].includes(
            JSON.parse(localStorage.getItem('currentUser')).roles
          ) ||
          !localStorage.getItem('one_organization')
        ) {
          next()
        } else {
          const respOrg = await listOrganization({ page: 1 })
          const resp = await branchesByOrganization({
            orgId: respOrg.data[0].id,
            active: true,
            page: 1,
          })

          if (resp.data.length === 1) {
            next({ path: `/filial/${resp.data[0].id}` })
          } else {
            next({
              name: 'organizacao',
              params: { id: respOrg.data[0].id },
            })
          }
        }
      },
    },
  },
  {
    path: '/organizacao/:id',
    name: 'organizacao',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/organization/DetailsOrganizations.vue')
      ),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        if (!localStorage.getItem('currentUser')) {
          next({ name: 'login' })
        } else if (
          ['admin_lecupon'].includes(
            JSON.parse(localStorage.getItem('currentUser')).roles
          ) ||
          !localStorage.getItem('one_organization')
        ) {
          next()
        } else {
          const respOrg = await listOrganization({ page: 1 })
          const resp = await branchesByOrganization({
            orgId: respOrg.data[0].id,
            active: true,
            page: 1,
          })
          if (resp.data.length === 1) {
            next({ path: `/filial/${resp.data[0].id}` })
          } else {
            next()
          }
        }
      },
    },
  },
  {
    path: '/nova-organizacao',
    name: 'nova-organizacao',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/organization/CreateOrganization.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        validateRootAccess(next)
      },
    },
  },
  {
    path: '/filiais',
    name: 'filiais',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/branch/ListBranches.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (!localStorage.getItem('currentUser')) {
          next({ name: 'login' })
        } else next()
      },
    },
  },
  {
    path: '/filial/:id',
    name: 'filial',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/branch/DetailsBranches.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (!localStorage.getItem('currentUser')) {
          next({ name: 'login' })
        } else next()
      },
    },
  },
  {
    path: '/nova-filial/:id',
    name: 'nova-filial',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/branch/CreateBranch.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        validateRootAccess(next)
      },
    },
  },
  {
    path: '/detalhe-promocao/:id',
    name: 'detalhe-promocao',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/promotion/DetailsPromotions.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (!localStorage.getItem('currentUser')) {
          next({ name: 'login' })
        } else next()
      },
    },
  },
  {
    path: '/lista-promocao',
    name: 'lista-promocao',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/promotion/ListPromotions.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (!localStorage.getItem('currentUser')) {
          next({ name: 'login' })
        } else next()
      },
    },
  },
  {
    path: '/editar-promocao/:id',
    name: 'editar-promocao',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/promotion/EditPromotion.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        validateAccess(next)
      },
    },
  },
  {
    path: '/nova-promocao',
    name: 'nova-promocao',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/promotion/CreatePromotion.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        validateAccess(next)
      },
    },
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () =>
      lazyLoadView(import('@/src/router/views/lecupon/users/ManagerUsers.vue')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        validateAccess(next)
      },
    },
  },
  {
    path: '/usuario/:id',
    name: 'usuario',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/users/UserInformation.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (!localStorage.getItem('currentUser')) {
          next({ name: 'login' })
        } else next()
      },
    },
  },
  {
    path: '/novo-usuario',
    name: 'novo-usuario',
    component: () =>
      lazyLoadView(import('@/src/router/views/lecupon/users/NewUser.vue')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        validateAccess(next)
      },
    },
  },
  {
    path: '/resgates',
    name: 'resgates',
    component: () =>
      lazyLoadView(import('@/src/router/views/lecupon/orders/ListOrders.vue')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        validateRootAccess(next)
      },
    },
  },
  {
    path: '/importar-resgates',
    name: 'importar-resgates',
    component: () => lazyLoadView(import('@/src/router/views/lecupon/orders/ImportOrders.vue')),
    meta: {
      beforeResolve (routeTo, routeFrom, next) {
        validateRootAccess(next)
      }
    }
  },
  {
    path: '/cashback',
    name: 'cashback',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/cashback/ListCashbacks.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        validateRootAccess(next)
      },
    },
  },
  {
    path: '/ferramentas',
    name: 'ferramentas',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/tools/tools-manager.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        validateRootAccess(next)
      },
    },
  },
  {
    path: '/ferramentas/importar-ordenacao',
    name: 'importar-ordenacao',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/tools/views/import-ordering.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        validateRootAccess(next)
      },
    },
  },
  {
    path: '/importar-cashback',
    name: 'importar-cashback',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/cashback/ImportCashbacks.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        validateRootAccess(next)
      },
    },
  },
  {
    path: '/lista-categorias',
    name: 'lista-categorias',
    component: () =>
      lazyLoadView(
        import('@/src/router/views/lecupon/categories/ListCategories.vue')
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        validateRootAccess(next)
      },
    },
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 200,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}

const validateAccess = (next) => {
  if (!localStorage.getItem('currentUser')) {
    next({ name: 'login' })
  } else if (
    ['admin_shopkeeper', 'admin_lecupon'].includes(
      JSON.parse(localStorage.getItem('currentUser')).roles
    )
  ) {
    next()
  } else {
    checkStartRoute(next)
  }
}

const validateRootAccess = async (next) => {
  if (!localStorage.getItem('currentUser')) {
    next({ name: 'login' })
  } else if (
    ['admin_lecupon'].includes(
      JSON.parse(localStorage.getItem('currentUser')).roles
    )
  ) {
    next()
  } else {
    checkStartRoute(next)
  }
}

const checkStartRoute = async (next) => {
  if (localStorage.getItem('one_organization')) {
    const respOrg = await listOrganization({ page: 1 })
    const resp = await branchesByOrganization({
      orgId: respOrg.data[0].id,
      active: true,
      page: 1,
    })
    if (resp.data.length === 1) {
      next({ path: `/filial/${resp.data[0].id}` })
    } else {
      next({
        name: 'organizacao',
        params: { id: respOrg.data[0].id },
      })
    }
  }
}
