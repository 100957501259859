import axios from 'axios'

const URL = process.env.VUE_APP_RAILS_URL

const listOrganization = async (body) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/organizations`

    const params = {
      page: body.page,
      active: body.active,
      name: body.name,
      category_id: body.category_id,
    }

    const response = await axios.get(endpoint, { params })

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const organizationById = async (id) => {
  try {
    let endpoint = `${URL}/shopkeeper/v2/organizations/${id}`
    const response = await axios.get(endpoint)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const editOrganization = async (id, body) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/organizations/${id}`
    const response = await axios.patch(endpoint, body)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const createOrganization = async (body) => {
  try {
    const data = {}
    const endpoint = `${URL}/shopkeeper/v2/organizations`
    if (body.description) data.description = body.description
    if (body.short_description) data.short_description = body.short_description
    if (body.cashback_value && body.cashback_type) {
      data.cashback_value = body.cashback_value
      data.cashback_type = body.cashback_type
    }
    if (body.facebook) data.facebook = body.facebook
    if (body.instagram) data.instagram = body.instagram
    if (body.logo_image) data.logo_image = body.logo_image
    if (body.photo) data.photo = body.photo
    if (body.tags) data.tags = body.tags
    if (body.category_ids.length > 0) data.category_ids = body.category_ids
    if (typeof body.active === 'boolean') data.active = body.active
    if (typeof body.pined === 'boolean') data.pined = body.pined

    data.promotion_providers = body.promotion_providers
    data.name = body.name
    data.category_id = body.category_id
    data.contact_telephone = body.contact_telephone
    data.cnpj = body.cnpj

    const response = await axios.post(endpoint, data)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const promotionsRedeemed = async (body) => {
  try {
    let endpoint = `${URL}/shopkeeper/v2/organizations/${
      body.branchId
    }/orders?page=${body.page}`
    const response = await axios.get(endpoint)
    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const editMultiCashback = async (body) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/organizations/multi_edit`
    const response = await axios.patch(endpoint, body)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const toogleOrganizationForAllCostumers = async (organizationId, toogleEnable) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/organizations/${organizationId}`
    const params = {
      all_projects: toogleEnable,
    }
    const response = await axios.patch(endpoint, params)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

export {
  listOrganization,
  organizationById,
  editOrganization,
  createOrganization,
  promotionsRedeemed,
  editMultiCashback,
  toogleOrganizationForAllCostumers,
}
