import axios from 'axios'

const URL = process.env.VUE_APP_RAILS_URL

const listBranches = async (id, page) => {
  try {
    let endpoint = `${URL}/shopkeeper/v2/branches`

    if (id) endpoint += `/${id}`
    if (page) endpoint += `?page=${page}`

    const response = await axios.get(endpoint)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const branchByTerm = async (body) => {
  try {
    let endpoint = `${URL}/shopkeeper/v2/branches?name=${encodeURIComponent(
      body.name
    )}`

    if (body.page) endpoint += `&page=${body.page}`

    const response = await axios.get(endpoint)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const addsOrganizationBranchesToShopkeeper = async (
  shopkeeperId,
  organizationId
) => {
  try {
    const body = {
      shopkeeper_id: shopkeeperId,
      organization_id: organizationId,
    }

    const endpoint = `${URL}/shopkeeper/v2/shopkeepers/${shopkeeperId}/organizations/${organizationId}`
    const response = await axios.post(endpoint, body)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const addsBranchesToShopkeeper = async (shopkeeperId, branchId) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/shopkeepers/${shopkeeperId}/branches/${branchId}`
    const response = await axios.post(endpoint)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const branchesByUserID = async (userID, page) => {
  try {
    let endpoint = `${URL}/shopkeeper/v2/users/${userID}/branches`
    if (page) endpoint += `?page=${page}`

    const response = await axios.get(endpoint)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const deleteBranchByShopkeeperId = async (shopkeeperId, branchId) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/shopkeepers/${shopkeeperId}/branches/${branchId}`
    const response = await axios.delete(endpoint)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const editBranch = async (id, body) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/branches/${id}`
    const response = await axios.patch(endpoint, body)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}
const editBranchAdmin = async (org, branch, body) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/organizations/${org}/branches/${branch}`
    const response = await axios.patch(endpoint, body)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const branchesByOrganization = async (body) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/organizations/${body.orgId}/branches`
    const params = {
      page: body.page,
      name: body.name ? encodeURIComponent(body.name) : null,
      full_address: body.address ? encodeURIComponent(body.address) : null,
      taxpayer_number: body.cnpj,
      active: body.active,
    }

    const response = await axios.get(endpoint, { params })

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const createBranch = async (body, orgId) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/organizations/${orgId}/branches`
    const response = await axios.post(endpoint, body)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const disableBranches = async (body) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/branches/bulk_edit`
    const response = await axios.patch(endpoint, body)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const exportBranchEmail = async (orgId) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/organizations/${orgId}/export_branches`
    const response = await axios.post(endpoint)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const importBranchFile = async (orgId, file) => {
  try {
    const endpoint = `${URL}/shopkeeper/v2/organizations/${orgId}/import_branches`

    const formData = new FormData()
    formData.set('file', file)

    const config = {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const response = await axios.post(endpoint, formData, config)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

const ordersByBranch = async (body) => {
  try {
    let endpoint = `${URL}/shopkeeper/v2/branches/${
      body.branchId
    }/orders?page=${body.page}`

    const response = await axios.get(endpoint)

    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    console.error(err.stack)
    return {
      status: err.response.status,
      message: err.response.data.error,
    }
  }
}

export {
  listBranches,
  branchByTerm,
  branchesByUserID,
  editBranch,
  editBranchAdmin,
  branchesByOrganization,
  createBranch,
  exportBranchEmail,
  importBranchFile,
  ordersByBranch,
  disableBranches,
  deleteBranchByShopkeeperId,
  addsOrganizationBranchesToShopkeeper,
  addsBranchesToShopkeeper,
}
