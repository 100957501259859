var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu",attrs:{"id":"side-menu"}},[_c('li',{staticClass:"menu-title"},[_vm._v("Bem Vindo "+_vm._s(_vm.username)+"!")]),_c('li',{staticClass:"cursor-pointer"},[_c('a',{staticClass:"has-dropdown",attrs:{"aria-expanded":"false"},on:{"click":_vm.checkStartRoute}},[_c('i',{staticClass:"fas fa-home"}),_c('span',[_vm._v("Início")])])]),(!_vm.onlyOneOrganization)?_c('li',[_vm._m(0)]):_vm._e(),_c('li',[_vm._m(1),_c('ul',{staticClass:"nav-second-level",attrs:{"aria-expanded":"false"}},[(['admin_lecupon','admin_shopkeeper'].includes(_vm.userAccess))?_c('li',[_vm._m(2)]):_vm._e(),_vm._m(3)])]),(['admin_lecupon'].includes(_vm.userAccess))?_c('li',[_vm._m(4)]):_vm._e(),(['admin_lecupon'].includes(_vm.userAccess))?_c('li',[_vm._m(5)]):_vm._e(),(['admin_lecupon','admin_shopkeeper'].includes(_vm.userAccess))?_c('li',[_vm._m(6)]):_vm._e(),(['admin_lecupon'].includes(_vm.userAccess))?_c('li',[_vm._m(7)]):_vm._e(),(['admin_lecupon'].includes(_vm.userAccess))?_c('li',[_vm._m(8)]):_vm._e(),_vm._m(9)]),_c('div',{staticClass:"clearfix"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-dropdown",attrs:{"href":"/organizacao","aria-expanded":"false"}},[_c('i',{staticClass:"fas fa-qrcode"}),_c('span',[_vm._v("Organizações")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-dropdown",attrs:{"href":"javascript: void(0);","aria-expanded":"false"}},[_c('i',{staticClass:"fas fa-ticket-alt"}),_c('span',[_vm._v("Promoções")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/nova-promocao"}},[_c('span',[_vm._v("Cadastrar Promoção")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/lista-promocao"}},[_c('span',[_vm._v("Gerenciar Promoções")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-dropdown",attrs:{"href":"/lista-categorias","aria-expanded":"false"}},[_c('i',{staticClass:"fas fa-list"}),_c('span',[_vm._v("Categorias")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-dropdown",attrs:{"href":"/resgates","aria-expanded":"false"}},[_c('i',{staticClass:"fas fa-receipt"}),_c('span',[_vm._v("Resgates")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-dropdown",attrs:{"href":"/usuarios","aria-expanded":"false"}},[_c('i',{staticClass:"fas fa-users"}),_c('span',[_vm._v("Usuários")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-dropdown",attrs:{"href":"/cashback","aria-expanded":"false"}},[_c('i',{staticClass:"fas fa-cash-register"}),_c('span',[_vm._v("Cashback")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"has-dropdown",attrs:{"href":"/ferramentas","aria-expanded":"false"}},[_c('i',{staticClass:"fas fa-cogs"}),_c('span',[_vm._v("Ferramentas")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"has-dropdown",attrs:{"href":"/logout","aria-expanded":"false"}},[_c('i',{staticClass:"fe-log-out mr-1"}),_c('span',[_vm._v("Sair")])])])
}]

export { render, staticRenderFns }