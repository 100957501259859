import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import VueTour from 'vue-tour'
import Vuelidate from 'vuelidate'
import KnobControl from 'vue-knob-control'
import VueDraggable from 'vue-draggable'
import VueMask from 'v-mask'
import VueStringFilter from 'vue-string-filter'
import VueRouter from 'vue-router'
import vco from 'v-click-outside'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd'

import App from './app'
import router from '@router'
import store from '@state/store'
import '@components/_globals'

import moment from 'moment'
import 'moment/locale/pt-br'

import Toast from 'vue-toastification'
import Print from 'vue-print-nb'
import VueHtml2Canvas from 'vue-html2canvas'

export const bus = new Vue()

Vue.use(VueRouter)
Vue.use(vco)

Vue.use(VueStringFilter)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(Vuelidate)
Vue.use(VueTour)
Vue.use(KnobControl)
Vue.use(VueDraggable)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueAxios, axios)
Vue.use(Toast)
Vue.use(Print)
Vue.use(VueHtml2Canvas)
Vue.component('ApexChart', VueApexCharts)

Vue.use(VuejsClipper)
Vue.use(VuejsClipper, {
  components: {
    clipperBasic: true,
    clipperFixed: true,
    clipperPreview: true,
  },
})

moment.locale('pt-BR')
Vue.prototype.moment = moment

axios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('currentUser')) {
      config.headers['X-Shopkeeper-Email'] = JSON.parse(
        localStorage.getItem('currentUser')
      ).token.email
      config.headers['X-Shopkeeper-Token'] = JSON.parse(
        localStorage.getItem('currentUser')
      ).token.auth_token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (config) => {
    return config
  },
  (error) => {
    if (error.response.status === 401) {
      router.push({ path: '/logout' })
    }
    return Promise.reject(error)
  }
)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
